<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center">
            <h5 class="text-h5 text-typo font-weight-bold">{{ 'Validating email'|trans }}</h5>
          </div>

          <div class="card-padding">
            <v-row v-if="localAlert">
              <v-col cols="12">
                <v-alert :type="localAlert.type" :color="localAlert.color">
                  {{ localAlert.message }}
                </v-alert>
              </v-col>
            </v-row>


            <v-btn
              elevation="0"
              :ripple="false"
              height="43"
              class="
                font-weight-bold
                text-uppercase
                btn-default
                bg-gradient-default
                py-2
                px-6
                me-2
                mt-6
                mb-2
                w-100
                text-white
              "
              color="#5e72e4"
              small
@click="goToHomepage"
              :loading="isLoading"
              >{{ 'Go to main page'|trans }}
            </v-btn>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "EmailValidation",
  data() {
    return {

      isLoading: true,
      localAlert: null,
    };
  },
  mounted() {
    this.handleSubmit();
  },
  computed: {

  },
  methods: {
    goToHomepage(){
      this.$router.push({'name':'landing-page'});
    },
    handleSubmit() {

      this.localAlert = null;
      //let $this = this;
      if (!this.$route.params.token) {
        this.localAlert = {
          type: "error",
          color: "red",
          message: this.$translator.trans("No token found!"),
        };
        return false;
      }

      let $this = this;
      fetch(process.env.VUE_APP_API_URL + "/email-validation/"+this.$route.params.token, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.$store.getters.getLocale,
        },

      })
          .then((response) => response.json())
          .then((data) => {
            if (data.type !== "success") {
              this.localAlert = {
                type: "error",
                color: "red",
                message: data.message ? data.message : this.$translator.trans("Validation failed!"),
              };

              this.isLoading = false;

              return false;
            }

            this.localAlert = {
              type: "success",
              color: "green",
              message: this.$translator.trans("Your email has been validated successfully!"),
            };

            setTimeout(function(){
                $this.goToHomepage();
            },5000);
          })
          .catch((error) => {
            console.error("Error:", error);

            this.localAlert = {
              type: "error",
              color: "red",
              message: error.message ? error.message : this.$translator.trans("Validation failed!"),
            };
          });

      this.isLoading = false;


    },
  },
};
</script>
